<template>
	<div class="page">
		<navbar />
		<breadcrumb :list="breadcrumbList" />
		<div class="main flex-direction-column">
			<div class="result-box">
				<searchResult style="margin-top: 0" :isShow="false" />
				<div class="mess flex-space-between" v-for="item in oeInfoList.length ? oeInfoList.slice(0, 1) : []" :key="item.brand_code">
					<div class="item flex-direction-column">
						<div class="title">OEM information:</div>
						<div class="desc flex">
							<div class="box flex">
								<span>OEM Code：</span>
								<span>{{ item.oe || "-" }}</span>
							</div>
							<div class="box flex-wrap">
								<span>OEM Name：</span>
								<span>{{ item.oe_name || "-" }}</span>
							</div>
						</div>
						<div class="desc flex-wrap">
							<div class="box flex">
								<span>PNC：</span>
								<span>{{ item.pnc || "-" }}</span>
							</div>
						</div>
					</div>
					<div class="item flex-direction-column">
						<div class="title">Replacement part information:</div>
						<div class="desc flex-wrap">
							<div class="color" v-for="(ii, vv) in item.replace_oe" :key="vv">{{ ii.replace_oe }}</div>
						</div>
					</div>
					<div class="item flex-direction-column">
						<div class="title">Price：</div>
						<div class="desc">
							<p>￥{{ item.price || "-" }}</p>
						</div>
					</div>
				</div>
				<div class="table flex-direction-column" v-if="isShow">
					<div class="tabs flex">
						<span>Result： {{ !tabsIndex ? firstTableData.length : Result }}</span>
						<div class="item flex-center" v-for="(item, index) in tabs" :key="item.name" :class="index == tabsIndex && 'active'" @click="tabsClick(index)">{{ item.name }}</div>
					</div>

					<el-table
						ref="multipleTable"
						tooltip-effect="dark"
						:data="!tabsIndex ? firstTableData : secondTableData"
						:header-cell-style="{ background: '#F7F7F7', color: '#000000' }"
						style="width: 100%"
						@filter-change="filterChange"
					>
						<el-table-column v-if="tabsIndex === 0" align="center" type="index" label="#" width="50" :key="Math.random()"> </el-table-column>
						<el-table-column v-if="tabsIndex === 0" align="center" prop="date" label="OES BRAND" key="OES BRAND"> </el-table-column>
						<el-table-column v-if="tabsIndex === 0" align="center" prop="name" label="OES CODE" key="OES CODE"> </el-table-column>
						<el-table-column v-if="tabsIndex === 0" align="center" prop="address" label="OES NAME" key="OES NAME"> </el-table-column>
						<el-table-column v-if="tabsIndex === 0" align="center" prop="date" label="COUNTRY" key="COUNTRY"> </el-table-column>

						<el-table-column v-if="tabsIndex" align="center" type="index" label="#" width="50" :key="Math.random()"> </el-table-column>
						<el-table-column
							v-if="tabsIndex"
							align="center"
							prop="pnc"
							label="PNC"
							key="pnc"
							width="80"
							column-key="pnc"
							:filters="filtersPnc"
							:filter-method="filterHandlerPnc"
							:filter-multiple="true"
						>
						</el-table-column>
						<!-- :filters="" :filter-method="" -->
						<el-table-column v-if="tabsIndex" align="center" prop="oe_code" label="OEM CODE" key="oe_code" width="110" :filters="filtersOeCode" :filter-method="filterHandlerOeCode"> </el-table-column>

						<el-table-column v-if="tabsIndex" align="center" prop="oe_name" label="OEM NAME" key="oe_name" :filters="filtersOeName" :filter-method="filterHandlerOeName"> </el-table-column>
						<el-table-column
							v-if="tabsIndex"
							align="center"
							:prop="index != 0 ? 'brand_name' : 'brand'"
							label="BRAND"
							width="100"
							key="brand_name"
							:filters="filtersBrandName"
							:filter-method="filterHandlerBrandName"
						>
						</el-table-column>
						<el-table-column
							v-if="tabsIndex"
							align="center"
							:prop="index != 0 ? 'car_model' : 'models'"
							label="Model"
							key="car_model"
							width="100"
							:filters="filtersCarModel"
							:filter-method="filterHandlerModel"
						>
						</el-table-column>
						<el-table-column
							v-if="tabsIndex"
							align="center"
							:prop="index != 0 ? 'car_code' : 'chassiscode'"
							label="Chassis_Code"
							key="car_code"
							width="140"
							:filters="filtersChassisCode"
							:filter-method="filterHandlerChassisCode"
						>
						</el-table-column>
						<el-table-column
							v-if="tabsIndex"
							align="center"
							:prop="index != 0 ? 'start_time' : 'sop'"
							label="Start_time"
							key="start_time"
							width="110"
							:filters="filtersStartTime"
							:filter-method="filterHandlerStarttime"
						>
						</el-table-column>
						<el-table-column
							v-if="tabsIndex"
							align="center"
							:prop="index != 0 ? 'end_time' : 'eop'"
							label="End_time"
							key="end_time"
							width="100"
							:filters="filtersEndTime"
							:filter-method="filterHandlerEndtime"
						>
						</el-table-column>
						<el-table-column
							v-if="tabsIndex"
							align="center"
							:prop="index != 0 ? 'engine' : 'enginemodel'"
							label="Engine"
							key="engine"
							width="100"
							:filters="filtersEngine"
							:filter-method="filterHandlerEngine"
						>
						</el-table-column>
						<el-table-column
							v-if="tabsIndex"
							align="center"
							:prop="index != 0 ? 'transmission' : 'transmissiontype'"
							label="Transmission"
							width="130"
							key="transmission"
							:filters="filtersTransmission"
							:filter-method="filterHandlerTransmission"
						>
						</el-table-column>

						<el-table-column v-if="tabsIndex && index == 0" align="center" prop="" label="illustration" key="illustration" width="100">
							<template slot-scope="scope">
								<div @click="showBtn(scope.row)">
									<el-button type="primary">image</el-button>
								</div>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>
		</div>

		<el-dialog title="illustration" :visible.sync="dialogVisible" width="50%">
			<div class="flex-direction-column" style="height: 80vh">
				<imageViewr ref="imageViewrRef" :imageUrl="imageViewr.imageUrl" :coordinates="imageViewr.pos_xy" />
				<span slot="footer" class="dialog-footer">
					<el-button @click="(dialogVisible = false), $refs.imageViewrRef.resetImage()">Cancel</el-button>
					<el-button type="primary" @click="(dialogVisible = false), $refs.imageViewrRef.resetImage()">Confirm</el-button>
				</span>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import { getOeCar, getOeInfo, getReplaceOe, getOePrice, getOeCarCn, getOeInfoCn, getReplaceOeCn, getOePriceCn } from "@/service/api/index.js";
import navbar from "@/components/navbar/index.vue";
import breadcrumb from "@/components/breadcrumb/index.vue";
import searchResult from "@/components/searchResult/index";
import { unique } from "./js/table.js";
import imageViewr from "../mainGroup/comp/imageViewr.vue";
export default {
	components: { navbar, breadcrumb, searchResult, imageViewr },
	data() {
		return {
			dialogVisible: false,
			tabs: [
				{
					name: "OES part list",
				},
				{
					name: "Compatible model list",
				},
			],
			isShow: false,
			tabsIndex: 1,
			firstTableData: [],
			secondTableData: [],

			// 4731807010
			params: {
				oe: "",
				brand_code: "",
			},
			mess: {},
			breadcrumbList: [],

			filtersPnc: [],
			filtersOeName: [],
			filtersOeCode: [],
			filtersBrandName: [],
			filtersCarModel: [],
			filtersChassisCode: [],
			filtersStartTime: [],
			filtersEndTime: [],
			filtersEngine: [],
			filtersTransmission: [],
			Result: 0,
			index: 0,

			imageViewr: {
				imageUrl: "",
				pos_xy: "",
			},
			oeInfoList: [],
		};
	},

	mounted() {
		this.params.oe = this.$route.query.val;
		this.index = this.$route.query.index;
		// this.breadcrumbList = ["OEM Code", this.params.oe];
		this.breadcrumbList = [
			{
				name: this.params.oe,
				path: "-",
			},
		];
		// console.log(this.$route);

		if (this.index == 0) {
			this.getOeInfoApi();
		} else {
			this.getOeInfoCnApi();
			// this.getReplaceOeCnApi();
			// this.getOePriceCnApi();
		}
	},

	methods: {
		getData(e) {
			if (e.length > 0) {
				let filtersPnc = [];
				let filtersOeName = [];
				let filtersOeCode = [];
				let filtersBrandName = [];
				let filtersCarModel = [];
				let filtersChassisCode = [];
				let filtersStartTime = [];
				let filtersEndTime = [];
				let filtersEngine = [];
				let filtersTransmission = [];

				e.forEach((item) => {
					let pnc = {
						text: item.pnc,
						value: item.pnc,
					};
					filtersPnc.push(pnc);
					let OeName = {
						text: item.oe_name,
						value: item.oe_name,
					};
					filtersOeName.push(OeName);
					let BrandName = {
						text: item.brand_name || item.brand,
						value: item.brand_name || item.brand,
					};
					filtersBrandName.push(BrandName);
					let Model = {
						text: item.car_model || item.models,
						value: item.car_model || item.models,
					};
					filtersCarModel.push(Model);
					let ChassisCode = {
						text: item.car_code || item.chassiscode,
						value: item.car_code || item.chassiscode,
					};
					filtersChassisCode.push(ChassisCode);

					let OeCode = {
						text: item.oe_code,
						value: item.oe_code,
					};
					filtersOeCode.push(OeCode);

					let StartTime = {
						text: item.start_time || item.sop,
						value: item.start_time || item.sop,
					};
					filtersStartTime.push(StartTime);
					let EndTime = {
						text: item.end_time || item.eop,
						value: item.end_time || item.eop,
					};
					filtersEndTime.push(EndTime);
					let Engine = {
						text: item.engine || item.enginemodel,
						value: item.engine || item.enginemodel,
					};
					filtersEngine.push(Engine);

					if (item.transmission || item.transmissiontype) {
						let Transmission = {
							text: item.transmission || item.transmissiontype,
							value: item.transmission || item.transmissiontype,
						};
						filtersTransmission.push(Transmission);
					}
				});

				this.filtersPnc = this.$tools.customSort(unique(filtersPnc), "text");

				this.filtersOeName = this.$tools.customSort(unique(filtersOeName), "text");

				this.filtersOeCode = this.$tools.customSort(unique(filtersOeCode), "text");
				this.filtersBrandName = this.$tools.customSort(unique(filtersBrandName), "text");

				this.filtersCarModel = this.$tools.customSort(unique(filtersCarModel), "text");

				this.filtersChassisCode = this.$tools.customSort(unique(filtersChassisCode), "text");

				this.filtersStartTime = this.$tools.customSort(unique(filtersStartTime), "text");
				this.filtersEndTime = this.$tools.customSort(unique(filtersEndTime), "text");
				this.filtersEngine = this.$tools.customSort(unique(filtersEngine), "text");
				this.filtersTransmission = this.$tools.customSort(unique(filtersTransmission), "text");
			}
		},

		tabsClick(index) {
			this.tabsIndex = index;
		},
		showBtn({ img, pos_xy }) {
			console.log("img", img);
			this.dialogVisible = true;

			this.imageViewr.imageUrl = img;
			this.imageViewr.pos_xy = pos_xy;
			console.log(this.imageViewr);
		},

		// 日本
		async getOeInfoApi() {
			const { datas } = await getOeInfo({ oe: this.params.oe });

			// this.mess.oe = this.$route.query.val;

			this.oeInfoList = await Promise.all(
				datas.items.map(async (element, index) => {
					element.price = await this.getOePriceApi(element.oe);
					element.replace_oe = await this.getReplaceOeApi(element.oe); // 等待 Promise 完成
					return element;
				})
			);

			console.log(this.oeInfoList);

			const oeCarResults = await Promise.all(
				datas.items.map((element, index) => {
					element.pos_xy = element.pos_xy.map((item) => {
						return { x: item[0], y: item[1], w: item[2], h: item[3] };
					});

					this.params.brand_code = element.brand_code;

					return this.getOeCarApi(element);
				})
			);

			// 将所有请求返回的数据合并到 tableData
			const mergedTableData = oeCarResults.flat();

			// 使用 Map 去重
			// let uniqueTableData = Array.from(new Map(mergedTableData.map((item) => [item.hw_id, item])).values());
			let uniqueTableData = Array.from(
				new Map(mergedTableData.map((item) => [`${item.brand}-${item.models}-${item.chassiscode}-${item.sop}-${item.eop}-${item.enginemodel}-${item.transmissiontype}`, item])).values()
			);

			this.secondTableData = this.$tools.sortCharAt([...this.secondTableData, ...uniqueTableData], this.index != 0 ? "brand_name" : "brand", this.index != 0 ? "car_model" : "models");

			this.getData(this.secondTableData);
			this.isShow = true;
			this.Result = this.secondTableData.length;
		},
		async getOeCarApi({ oe, pnc, oe_name, brand_code, img, pos_xy }) {
			const { datas } = await getOeCar({ oe, brand_code });

			if (!datas) return [];

			const _data = datas.items.map((item) => {
				item.pnc = pnc;
				item.oe_name = oe_name;
				item.oe_code = oe;
				item.img = img;
				item.pos_xy = pos_xy;
				return item;
			});

			return _data;
		},
		async getReplaceOeApi(oe) {
			const { datas } = await getReplaceOe({ oe });

			return datas && datas.items.length ? datas.items : [];
		},
		async getOePriceApi(oe) {
			const { datas } = await getOePrice({ oe });

			return datas ? datas.price : "";

			// this.mess = { ...this.mess, ...datas };
		},

		// 中国
		async getOeInfoCnApi() {
			const { datas } = await getOeInfoCn({ oe: this.params.oe });
			console.log("getOeInfoCnApi", datas.items);

			this.oeInfoList = await Promise.all(
				datas.items.map(async (element, index) => {
					element.price = await this.getOePriceCnApi(element.oe);
					element.replace_oe = await this.getReplaceOeCnApi(element.oe); // 等待 Promise 完成
					return element;
				})
			);

			const oeCarResults = await Promise.all(
				datas.items.map((element, index) => {
					this.params.brand_code = element.brand_code;

					return this.getOeCarCnApi(element);
				})
			);

			// 将所有请求返回的数据合并到 tableData
			const mergedTableData = oeCarResults.flat();

			// 使用 Map 去重
			const uniqueTableData = Array.from(new Map(mergedTableData.map((item) => [item.hw_id, item])).values());

			this.secondTableData = this.$tools.sortCharAt([...this.secondTableData, ...uniqueTableData], "oe_code", this.index != 0 ? "car_model" : "models", this.index != 0 ? "start_time" : "sop");
			this.getData(this.secondTableData);
			this.isShow = true;
			this.Result = this.secondTableData.length;
		},
		async getOeCarCnApi({ pnc, oe_name, oe, brand_code }) {
			const { datas } = await getOeCarCn({ oe, brand_code });
			console.log("getOeCarCnApi", datas);

			if (!datas) return [];

			const _data = datas.items.map((item) => {
				item.pnc = pnc;
				item.oe_name = oe_name;
				item.oe_code = oe;
				return item;
			});

			return _data;

			// this.secondTableData = this.$tools.sortCharAt(_data, "oe_code", "car_model", "start_time");
			// // this.secondTableData = [...this.secondTableData, ...this.$tools.sortCharAt(_data, "oe_code", "car_model", "start_time")];
			// this.Result = this.secondTableData.length;
			// console.log("datas", this.secondTableData);
			// this.getData(this.secondTableData);
			// this.isShow = true;
		},
		async getReplaceOeCnApi(oe) {
			const { datas } = await getReplaceOeCn({ oe });
			return datas && datas.items.length ? datas.items : [];
		},

		async getOePriceCnApi(oe) {
			const { datas } = await getOePriceCn({ oe });

			return datas ? datas.price : "";
		},

		filterHandlerPnc(value, row, column) {
			return row.pnc == value;
		},
		filterHandlerOeName(value, row) {
			return row.oe_name == value;
		},
		filterHandlerBrandName(value, row) {
			return row.brand_name || row.brand == value;
		},
		filterHandlerModel(value, row) {
			return row.car_model || row.models == value;
		},
		filterHandlerChassisCode(value, row) {
			return row.car_code || row.chassiscode == value;
		},
		filterHandlerStarttime(value, row) {
			return row.start_time || row.sop == value;
		},
		filterHandlerEndtime(value, row) {
			return row.end_time || row.eop == value;
		},
		filterHandlerEngine(value, row) {
			return row.engine || row.enginemodel == value;
		},
		filterHandlerOeCode(value, row, column) {
			return row.oe_code == value;
		},
		filterHandlerTransmission(value, row, column) {
			const property = column["property"];
			return row[property] === value;
		},

		filterChange() {
			this.Result = this.$refs.multipleTable.tableData.length;
			console.log(this.Result, "this.multipleTable", this.$refs.multipleTable.tableData);
		},
	},
};
</script>

<style scoped lang="less">
.active {
	color: #fd7e14 !important;
	background-color: #fff !important;
}
.page {
	background-color: #f8f9fb !important;
	padding-bottom: 30px;
	min-height: calc(100vh - 30px);
}
.main {
	padding: 25px;
	background-color: #fff;
	width: 1350px;
	margin: 15px auto 0px auto;
	border: 1px solid #e6e6e6;
}
.mess {
	padding: 15px 17px;
	background: #fff1e2;
	margin-top: 15px;
	align-items: flex-start;
	.item {
		&:nth-child(1) {
			flex: 4;
		}
		&:nth-child(2) {
			flex: 3;
			margin: 0 15px;
		}
		&:nth-child(3) {
			flex: 1;
		}
		.title {
			font-size: 16px;
			color: #000000;
			margin-bottom: 5px;
		}
		.desc {
			margin-top: 10px;
			.box {
				&:nth-child(1) {
					width: 35%;
					// border: 1px solid #000;
				}
				&:nth-child(2) {
					flex: 1;
					margin-left: 10px;
					// border: 1px solid #000;
				}
				span {
					font-size: 16px;
					&:nth-child(1) {
						color: #999999;
					}
					&:nth-child(2) {
						color: #000000;
					}
				}
			}
			.color {
				font-size: 16px;
				color: #fd7e14;
				padding: 6px 19px;
				border: 1px solid #fd7e14;
				background-color: #fff;
				margin-right: 15px;
				margin-bottom: 5px;
				&:last-child {
					margin-right: 0;
				}
			}
			p {
				font-weight: 500;
				font-size: 16px;
				color: #fd7e14;
				margin: 0;
				padding: 0;
			}
		}
	}
}
.table {
	border-top: 1px solid #e6e6e6;
	margin-top: 15px;
	.tabs {
		margin-top: 10px;
		position: relative;
		border-bottom: 1px solid #fd7e14;
		span {
			position: absolute;
			font-weight: 500;
			font-size: 16px;
			color: #000000;
			left: 0;
		}
		.item {
			width: 50%;
			padding: 15px 0;
			cursor: pointer;
			background-color: #f7f7f7;
		}
	}
}
</style>
